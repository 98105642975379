<template v-slot:default="{ pending, error, data }">
	<img v-if="data" :src="data.media_details.sizes.full.source_url">
	<img v-else src="https://www.wearlight.com/api/wp-content/uploads/2021/05/product-sample.png">
</template>

<script>
import axios from 'axios';
export default {
	data() {
    return {
      pending: true,
      error: false,
      data: null
    };
  },
	props: {
		media_id: { type: Number, required: true }
	},
  watch: {
    media_id: {
      handler() {
        this.requestData();
      },
      deep: true
    }
  },
  mounted() {
    this.requestData();
  },
  methods: {
    async requestData() {
      this.pending = true;
      try {
        const { data } = await axios.get(process.env.VUE_APP_API_URL + '/wp/v2/media/' + this.media_id);
        this.data = data;
        this.error = false;
      } catch (e) {
        this.data = null;
        this.error = e;
      }
      this.pending = false;
    }
  },
  render() {
    return this.$scopedSlots.default({
      pending: this.pending,
      error: this.error,
      data: this.data
    });
  }
}
</script>

<style>

</style>