<template>
	<div class="sustainability">
		<section class="sustainability--intro" v-if="Object.keys(fields).length">
			<div class="title">
				<h3 v-for="(title, index) in fields.titolo_formattato.split('#')" :key="index" :class="{'withSvg' : thereIsAnd(title)}">
					<span v-for="(tit, ind) in title.split('&')" :key="ind">{{tit}} <AndSVG class="and" v-if="thereIsAnd(title) && ind==0" /></span>
				</h3>
			</div>
			<div class="image">
				<ImageById :media_id="fields.immagine_su_titolo" />
			</div>
		</section>
		<section class="sustainability--text small-padding">
			<div class="text--wrapper" v-if="Object.keys(fields).length">
				<h3>
					<span v-for="(text, index) in fields.titolo_2.split(/\r?\n/)" :key="index">{{text}}</span>
				</h3>
				<div class="blocks" v-for="(testo, index) in fields.testi_2" :key="index">
					<div v-if="therIsSharp(testo.testo)">
						<div class="blk" v-for ="(block, ind) in testo.testo.split('#')" :key="ind">
							<p>{{block}}</p>
							<router-link v-if="ind == 0" :to="$i18nRoute({ name: 'Patent', params: {slug: 'reborn'}})"><ImageById :media_id="fields.logo_reborn" /></router-link>
						</div>
					</div>
					<p v-else>{{testo.testo}}</p>
				</div>
			</div>
		</section>
		<section class="sustainability--image" v-if="Object.keys(fields).length">
			<ImageById :media_id="fields.immagine" />
		</section>		
		<section class="sustainability--text">
			<div class="text--wrapper" v-if="Object.keys(fields).length">
				<p v-for="(testo, index) in fields.testi_1" :key="index">{{testo.testo}}</p>
			</div>
		</section>
		<section class="sustainability--next-page" v-if="Object.keys(nextFields).length">
			<div class="next--wrapper">
				<router-link  :to="$i18nRoute({ name: 'Technology' })">
					<div class="title">
						<h3 v-for="(title, index) in nextFields.titolo_formattato.split('#')" :key="index" :class="{'withSvg' : thereIsAnd(title)}">
							<span v-for="(tit, ind) in title.split('&')" :key="ind">{{tit}} <AndSVG class="and" v-if="thereIsAnd(title) && ind==0" /></span>
						</h3>
					</div>
					<div class="image">
						<ImageById :media_id="nextFields.immagine_su_titolo" />
					</div>
				</router-link>
			</div>
		</section>
	</div>
</template>

<script>
import axios from 'axios';
import AndSVG from '@/components/elements/AndSVG';
import ImageById from '@/components/ImageById';
export default {
	data() {
		return {
			fields: [],
			nextFields: [],
			page: parseInt(this.$t('sostenibilita.page_id')),
			nextPage: parseInt(this.$t('tecnologia.page_id'))
		}
	},
	components: {
		AndSVG,
		ImageById
	},
	metaInfo() {
		return{
			title: this.$t('head.title.sostenibilita'),
			titleTemplate: '%s | Wearlight',
		}
  },
	methods: {		
		thereIsAnd(string){
			if(string.includes('&')){
				return true;
			}else{
				return false;
			}
		},
		therIsSharp(string){
			if(string.includes('#')){
				return true;
			}else{
				return false;
			}
		},
		getPage() {
			axios
				.get(process.env.VUE_APP_API_URL + '/wl/v1/acf/getfieldbyid/' + this.page)
				.then(response => {
					this.fields = response.data
					console.log(this.fields)
				})
		},
		getNext() {
			axios
				.get(process.env.VUE_APP_API_URL + '/wl/v1/acf/getfieldbyid/' + this.nextPage)
				.then(response => {
					this.nextFields = response.data
				})
		}
	},
	watch: {
		'$route.params.locale': function(){
			this.page = parseInt(this.$t('sostenibilita.page_id'))
			this.nextPage = parseInt(this.$t('tecnologia.page_id'))
			this.getPage()
			this.getNext()
		}
  },
	beforeMount() {
		this.getPage()
		this.getNext()
	}
}
</script>

<style lang="scss">
.sustainability {
	position: relative;
	width: 100%;
	height: auto;
	@include mobile {
		padding-top: 60px;
	}
	.sustainability--intro {
		position: relative;
		width: 100%;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		@include mobile {
			height: auto;
			padding: 10vh 0
		}
		.title {
			position: relative;
			width: 60vw;
			display: flex;
			flex-direction: column;
			@include mobile {
				width: 80vw;
				z-index: 3;
			}
			h3 {
				font-family: 'Suisse Int';
				font-weight: 500;
				color: $black;
				font-size: 10vw;
				line-height: 10vw;
				text-transform: uppercase;
				margin: 0;
				@include mobile {
					font-size: 15vw;
					line-height: 15vw;
				}
				span {
					display: flex;
					align-items: center;
				}
				.and {
					position: relative;
					height: 8vw;
					width: auto;
					display: inline-block;
					path {
						fill: $black;
					}
					@include mobile {
						height: 10vw;
					}
				}
				&.withSvg {
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}
				&:first-of-type {
					align-self: flex-start;
				}
				&:nth-of-type(2) {
					align-self: flex-end;
				}
			}
		}
		.image {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 13vw;
			height: auto;
			transform: translate(-50%, -50%);
			@include mobile {
				width: 50vw;
				z-index: 2;
			}
			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}
	}
	.sustainability--text {
		position: relative;
		width: 100%;
		padding: 20vh 0;
		display: flex;
		justify-content: center;
		align-items: center;
		@include mobile {
			padding: 10vh 0;
		}
		.text--wrapper {
			position: relative;
			width: 50%;
			height: auto;
			display: flex;
			flex-direction: column;
			@include mobile {
				padding: 0 5vw;
				width: 100%;
			}
			h3 {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin: 0 0 20vh 0;
				span {
					text-transform: uppercase;
					font-family: 'Saol Display';
					font-size: 4vw;
					line-height: 4vw;
					font-weight: 400;
					color: $black;
					@include mobile {
						font-size: 8vw;
						line-height: 8vw;
					}
				}
				@include mobile {
					margin: 0 0 5vh 0;
				}
			}
			.blocks {
				position: relative;
				width: 100%;
				height: auto;
				.blk {
					position: relative;
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-end;
					p {
						color: $black;
						font-size: 24px;
						font-weight: 400;
						font-family: 'Suisse Int';
						width: 70%;
						&:last-of-type {
							margin: 0;
						}
						@include mobile {
							width: 100%;
							font-size: 16px;
							line-height: 16px;
						}
					}
					a {
						position: relative;
						width: 70%;
						display: flex;
						justify-content: center;
						align-items: flex-start;
						margin: 5vh 0;
						img {
							position: relative;
							width: 100px;
						}
					}
				}
			}
			p {
				color: $black;
				font-size: 24px;
				font-weight: 400;
				font-family: 'Suisse Int';
				width: 70%;
				align-self: flex-end;
				margin: 0 0 5vh 0;
				&:last-of-type {
					margin: 0;
				}
				@include mobile {
					width: 100%;
					font-size: 16px;
					line-height: 16px;
				}
			}
		}
		&.small-padding {
			padding: 0 0 20vh 0;
			@include mobile {
				padding: 10vh 0;
			}
		}
	}
	.sustainability--image {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;
		@include mobile {
			padding: 0 5vw;
			box-sizing: border-box;
		}
		img {
			position: relative;
			width: 40vw;
			padding-left: 20vw;
			@include mobile {
				width: 70vw;
				padding-left: 0;
			}
		}
	}
	.sustainability--next-page {
		position: relative;
		width: 100%;
		height: 100vh;
		background-color: $red;
		display: flex;
		align-items: center;
		justify-content: center;
		@include mobile {
			height: 50vh;
		}
		.next--wrapper {
			position: relative;
			width: 100%;
			height: auto;
			@include mobile {
				width: 100%;
				height: 100%;
			}
			a {
				position: relative;
				display: block;
				@include mobile {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
				}
				.title {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					@include mobile {
						width: 80%;
						z-index: 6;
					}
					h3 {
						font-family: 'Suisse Int';
						font-weight: 500;
						color: $white;
						font-size: 10vw;
						line-height: 10vw;
						text-transform: uppercase;
						margin: 0;
						span {
							display: flex;
							align-items: center;
						}
						svg {
							position: relative;
							height: 8vw;
							width: auto;
							display: inline-block;
							path {
								fill: $white;
							}
						}
						&.withSvg {
							display: flex;
							justify-content: flex-start;
							align-items: center;
						}
						@include mobile {
							font-size: 15vw;
							line-height: 15vw;
						}
					}
				}
				.image {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 13vw;
					height: auto;
					transform: translate(-50%, -50%);
					img {
						width: 100%;
						height: auto;
						display: block;
					}
					@include mobile {
						width: 50vw;
						z-index: 5;
					}
				}
			}
		}
	}
}
</style>